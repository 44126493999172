import Job from "../../Entities/Job";
import Layout from "../../components/layout";
import JobDetailsView from "../../components/Jobs/jobDetailsView";
import { useEffect, useState } from "react";
import { collection, getDocs, getFirestore, where, query } from "firebase/firestore"; 
import firebaseApp from "../../utils/firebaseApp";

const JobView = (props) => {
  const [job, setJob] = useState<Job>({
    jobId: "-999",
    jobTitle: ""
  });
  const title = props.title;
  useEffect(() => {
    async function getData() {
      const db = getFirestore(firebaseApp);
      const jobCollection = collection(db, 'jobs');
      const q = query(jobCollection, where("jobUrl", "==", title));
      const jobList: Array<Job> = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const jobData = doc.data();
        setJob({...jobData});
      });      
    }
    getData();
  },[]);
  let pageTitle = "RemoteInclusive - Job Post";
  let description = "RemoteInclusive - Inclusive and Transparent Job Listings - Job Post";
  if(job && job.jobTitle && job.company) {
    pageTitle = `RemoteInclusive - ${job?.jobTitle} - ${job?.company}`;
    description = `RemoteInclusive - Inclusive and Transparent Job Listings - Job Post - ${job?.jobTitle} - ${job?.company}`;
  }
  return (
    <Layout 
      description={description} 
      pageTitle={pageTitle}>
      <JobDetailsView job={job} />
      <div className="page-layout__background">
        <div className="page-layout__bg-magenta" />
        <div className="page-layout__bg-blue" />
        <div className="page-layout__bg-purple" />
      </div>
      
    </Layout>
  );
};

export default JobView;